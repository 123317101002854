section.article{
    width: 100%;
    height: 100vh;
    background-color: var(--second-bg-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
    padding: 1rem;
    overflow-y: scroll;
    padding: 1rem;
    padding-top: 70px;
    display: none;
}
section.article{
    scroll-behavior: smooth;
    scroll-padding-top: 5rem;
  }
  section.article::-webkit-scrollbar {
    width: .3rem;
  }
  section.article::-webkit-scrollbar-track {
    background: var(--bg-color);
  }
  section.article::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 15px;
  }
section.article.active{
    display: block;
}
section.article.active h2{
    font-size: 1.2rem;
    margin-bottom: .6rem;
}
section.article.active p{
    font-size: 1rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    color: #ffffffc4;
    text-transform: lowercase;
}
section.article.active p::first-letter{
    text-transform: uppercase;
}
section.article.active button{
    font-size: 1.4rem;
    cursor: pointer;
    color: #fff;
    background-color: var(--second-bg-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 8px #fff ;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 10px;
    right: 20px;
}
@media screen and (max-width: 510px) {
    section.article.active h2{
        font-size: 1rem;
        margin-bottom: .6rem;
    }
}