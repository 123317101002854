footer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    background-color: var(--second-bg-color);
    gap: 10px;
}
footer.maxWidth .box{
    width: 30%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
footer.maxWidth .box img{
    width: 200px;
    height: 100px;
    object-fit: cover;
}
footer.maxWidth .box-border{
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
footer.maxWidth .box-border a{
    color: #ffffff8e;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
}
footer.maxWidth .box-border a:hover{
    color: #fff;
}
footer.maxWidth .box-border a .icons{
    color: var(--main-color);
}

footer.maxWidth .button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: auto;
}
footer.maxWidth .button a{
    font-size: 1.2rem;
    background-color: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    transition: all .3s;
}
footer.maxWidth .button a:hover{
    background-color: var(--main-color);
    color: #000;
}
@media screen and (max-width: 821px) {
    footer.maxWidth{
        padding-bottom: 150px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    footer.maxWidth .box.img{
        width: auto;
        height: auto;
    }
    footer.maxWidth .box-border{
        width: 100%;
        height: 100px;
        justify-content: center;
        align-items: center;
    }
    footer.maxWidth .button{
        width: 100%;
        height: 100px;
    }
}
@media screen and (max-width: 350px) {
    footer.maxWidth .button{
        width: 100%;
        height: 100px;
        gap: 8px;
    }
    footer.maxWidth .box-border p{
        font-size: .8rem;
    }
}
@media screen and (max-width: 280px) {
    footer.maxWidth .box-border p{
        font-size: .7rem;
    }
}