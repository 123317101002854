section.form{
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../asset/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
section.form .back{
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    padding: .5rem 1.2rem;
    border-radius: 10px;
    box-shadow: 0 0 15px #0000007c;
    color: #000;
}
section.form .back:hover{
    box-shadow: 0 0 15px #000;
}
section.form form{
    padding: 2rem;
    background-color: #fff;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px #0000007c;
}
section.form form .img{
    width: 150px;
    height: 70px;
}
section.form form .img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.form form h3{
    color: #000;
}
section.form form input,section.form form textarea{ 
    width: 100%;
    height: 40px;
    background-color: transparent;
    color: #000;
    border: 2px solid #00000091;
    border-radius: 7px;
    text-indent: 1rem;
}
section.form form input:focus,section.form form textarea:focus{
    border-color: #000;
}
section.form form textarea{
    padding: 0.5rem 1rem;
    text-indent: 0px;
    height: 60px;
    border: 2px solid #00000091;
}
section.form form button{
    width: 100%;
    height: 40px;
    background-color: var(--main-color);
    color: #000;
    border-radius: 10px;
    cursor: pointer;
}
section.form form button .loader{
    font-size: 2rem;
    animation: loader .5s ease-in-out 0.5s infinite;
}
@keyframes loader {
    100%{
        transform: rotate(360deg);
    }
}
@media screen and (max-width: 500px) {
    section.form{
        background-image: none;
        background-color: #fff;
    }
    section.form form{
        width: 100%;
        box-shadow: 0 0 15px #fff;   
    }
}
@media screen and (max-width: 344px) {
    section.form form{
        width: 100%;
        padding: .5rem;
        
    }
}
@media screen and (max-width: 292px) {
    section.form form h3{
        font-size: 1rem;
        
    }
}
@media screen and (max-width: 280px) {
    section.form form input,section.form form textarea{
        font-size: .8rem;
    }
}
@media screen and (max-width: 250px) {
    section.form form h3{
        font-size: .9rem;
        
    }
}