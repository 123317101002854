section.question{
    padding: 1.5rem 0;
    text-align: center;
    box-sizing: border-box;
    background-color: var(--second-bg-color);
}
section.question .flex--container{
    margin: 0 auto;
    width: 80%;
    height: 500px;
}
section.question .flex--container .flex--items{
    width: 100%;
    height: 50%;
    opacity: 0;
}
section.question .flex--container .flex--items img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
section.question .flex--container .flex--items.one{
    transform: translateX(-50px);
}
section.question .flex--container .flex--items.two{
    display: flex;
    height: auto;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transform: translateX(-50px);
}
section.question .flex--container .flex--items.one.active{
    transform: translateX(0px);
    opacity: 1;
}
section.question .flex--container .flex--items.two.active{
    transform: translateX(0px);
    opacity: 1;
}

@media screen and (min-width: 500px) {
    section.question .flex--container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    section.question .flex--container .flex--items{
        width: 50%;
        height: 100%;
    }
}
@media screen and (max-width: 500px) {
    section.question .flex--container{
        height: auto;
    }
}