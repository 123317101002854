section.dineroli{
    width: 100%;
    height: 100vh;
    padding: 1rem;
}
section.dineroli .back{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    color: #000;
    font-weight: 6000;
}
.chamber{
    padding: 1rem;
}
.chamber h1{
    color: var(--main-color);
}
.chamber article{
    padding-left: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.chamber article a{
    color: #ffffffb4;
}
@media screen and (max-width: 470px) {
    .chamber h1{
        font-size: 1.5rem;
    }
    .chamber article a{
        font-size: .9rem;
    }
}
section.dineroli section.massive--link{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: repeat(2, 49%);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}
section.dineroli section.massive--link .link{
    width: 100%;
    height: 100%;
    background-color: #ffffffa1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
}
section.dineroli section.massive--link .link:hover{
    background-color: #fff;
    border-color: var(--main-color);
}
section.dineroli section.massive--link .link h1{
    font-size: 3rem;
    color: #000;
}
section.dineroli section.massive--link .link:hover h1{
    color: var(--main-color);
    -webkit-text-stroke: 1px black;
}
@media screen and (max-width: 752px) {
    section.dineroli section.massive--link .link h1{
        font-size: 2rem;
    }
}
@media screen and (max-width: 523px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 371px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 328px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 298px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1rem;
    }
}