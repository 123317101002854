section.path{
    text-align: center;
    padding-top: 1.2rem;
}
section.path .imgcontainer{
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.path .imgcontainer .img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
section.path .imgcontainer .img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.path article{
    background-color: #000000ab;
    width: 600px;
    min-height: 300px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    transform: translateY(-50px);
    opacity: 0;
}
section.path article.active{
    transform: translateY(0px);
    opacity: 1;
}
section.path article .btnw{
    background-color: rgba(0, 238, 255, 0.473);
    border: 2px solid var(--main-color);
    padding: .8rem 1.2rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
section.path article .btnw:hover{
    background-color: var(--main-color);
    color: #000;
}
section.path article .btnw .logo{
    font-size: 1.4rem;
}
@media screen and (max-width: 500px) {
    section.path article{
        width: 90%;
    }
}
@media screen and (max-width: 324px) {
    section.path article{
        padding: 1rem;
    }
}