section.create{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
section.create .flex-continer{
    border-radius: 30px;
    width: 90%;
    max-width: 1100px;
    height: 200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    overflow-x: auto;
}
section.create ::-webkit-scrollbar {
    width: 3rem;
    height: 0px;
  }

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: none !important;
  }
.slick-prev,.slick-next {
    display: none !important;
}
section.create .flex-continer article{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    height: 150px;
}
section.create .flex-continer article img{
    width: 100%;
    height: 100%;
}
/* 
  section.create ::-webkit-scrollbar-track {
    background: var(--bg-color);
    width: 1rem;
  }
  section.create ::-webkit-scrollbar-thumb {
    background: var(--main-color);
  } */